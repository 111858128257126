import React, { useContext, createContext, useState } from "react";
import { store } from "../helper/Store";
import { useApi } from "./VtigerApiProvider";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const api = useApi();
    const [userData, setUserData] = useState({});
    const [token, setToken] = useState(state.token);

    // const navigate = useNavigate();
    const login = async (username, password) => {
        return new Promise((resolve, reject) => {
            const request = api.prepareRequest({ '_operation': 'login', 'username': username, 'password': password }, 'application/json');
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(auth => {
                if (auth.success && auth.result.login !== undefined) {
                    setToken(auth.result.login.session);
                    dispatch({ type: 'set_token', token: auth.result.login.session });
                    setUserData({ username: username, vtSession: auth.result.login.session, auth: auth.result.login });
                    dispatch({ type: 'login' });
                    return resolve(auth.result.login);
                }
                return reject(auth);
            });

        });
    };

    const logout = () => {
        setUserData({});
        dispatch({ type: 'destroy_token' });
        dispatch({ type: 'loaded' });
        dispatch({ type: 'logout' });
        setToken("");
    };

    return <AuthContext.Provider value={{ login, logout, token, userData }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};