import React, {createContext, useReducer} from 'react';

/**
 * Initial State: Add initial global states here
 *
 * @author Kiren James <kiren@radus28.com>
 *
 * @internal DONE Zoom Fix
 *
 * @type {{messages: *[], zoomInit: boolean}}
 */
const initialState = {
    isLoggedIn: false,
    isLoading: false,
    isModalLoading: false,
    isUploading: false,
    userData: {},
    token: "",
    popUpAlertState: {
        showPopUpAlert: false,
        popUpAlertStatus: "",
        popUpAlertTitle: "",
        popUpAlertDescription: ""
    },
    entityDescribe: {},
};
const store = createContext(initialState);
const { Provider } = store;

/**
 * @author Kiren James <kiren@radus28.com>
 *
 * @internal DONE Zoom Fix
 *
 * @param children {JSX.Element}
 *
 * @return {JSX.Element}
 */
const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
        switch(action.type) {
            case 'login':
                return {
                    ...state,
                    isLoggedIn: true
                };
            case 'loaded':
                return {
                    ...state,
                    isLoading: false
                };
            case 'loading':
                return {
                    ...state,
                    isLoading: true
                };
            case 'modalLoaded':
                return {
                    ...state,
                    isModalLoading: false
                };
            case 'modalLoading':
                return {
                    ...state,
                    isModalLoading: true
                };
            case 'uploaded':
                return {
                    ...state,
                    isUploading: false
                };
            case 'uploading':
                return {
                    ...state,
                    isUploading: true
                };
            case 'set_user_data':
                return {
                    ...state,
                    userData: action.userData
                };
            case 'set_token':
                return {
                    ...state,
                    token: action.token
                }
            case 'logout':
                return {
                    ...state,
                    isLoggedIn: false
                };
            case 'reset_user_data':
                return {
                    ...state,
                    userData: {}
                };
            case 'destroy_token':
                return {
                    ...state,
                    token: ""
                }
            case 'show_popup_alert':
                return {
                    ...state,
                    popUpAlertState: action.popUpAlertState
                }
            case 'hide_popup_alert':
                return {
                    ...state,
                    popUpAlertState: {
                        showPopUpAlert: false,
                        popUpAlertStatus: "",
                        popUpAlertTitle: "",
                        popUpAlertDescription: ""
                    }
                }
            case 'set_entity_describe':
                return {
                    ...state,
                    entityDescribe: action.entityDescribe
                }
            default:
                throw Error('Unknown action: ' + action.type);
        }
    }, initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }