import React, {useContext, useRef} from 'react';
import './App.css';
import {Box, extendTheme, ChakraProvider } from '@chakra-ui/react'
import Login from './screens/Login'
import AuthProvider from "./api/AuthProvider";
import Dashboard from "./pages/Dashboard";
import AppHeader from "./components/AppHeader";
import ApiProvider from "./api/VtigerApiProvider";
import {store} from "./helper/Store";
import PopUpAlert from "./components/PopUpAlert";

const colors = {
  brand: {
    900: '#fa365d',
    800: '#f53e75',
    700: '#fa69ac'
  }
};

const auzyTheme = extendTheme({ colors });

function App() {
    const globalState = useContext(store);
    const {state, } = globalState;
    const matrix1Ref = useRef(null);
    const matrix2Ref = useRef(null);
    const matrix3Ref = useRef(null);
    const matrix4Ref = useRef(null);

    const RenderApp = () => {
        if ( state.isLoggedIn ) {
            return <Dashboard matrix1Ref={matrix1Ref} matrix2Ref={matrix2Ref} matrix3Ref={matrix3Ref} matrix4Ref={matrix4Ref} />;
        }
        return <Login />;
    };

    return (
        <ApiProvider>
            <AuthProvider>
              <ChakraProvider theme={auzyTheme}>
                  <Box sx={{ height: 'calc(100vh)' }}>
                      <AppHeader matrix1Ref={matrix1Ref} matrix2Ref={matrix2Ref} matrix3Ref={matrix3Ref} matrix4Ref={matrix4Ref} />
                      {RenderApp()}
                      <Box
                          position='absolute'
                          sx={{
                              right: 0,
                              left: 0,
                              bottom: 0
                          }}
                      >
                          <PopUpAlert />
                      </Box>
                  </Box>
              </ChakraProvider>
            </AuthProvider>
        </ApiProvider>
    );
}

export default App;
