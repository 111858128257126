import React, {useEffect, useContext} from "react";
import {Alert, AlertDescription, AlertIcon, AlertTitle, Box, Fade} from "@chakra-ui/react";
import {store} from "../helper/Store";

const PopUpAlert = () => {
    const globalState = useContext(store);
    const {state, dispatch} = globalState;

    useEffect(() => {
        const timeId = setTimeout(() => {
            // After 3 seconds set the show value to false
            dispatch({ type: 'hide_popup_alert' });
        }, 3000);

        return () => {
            clearTimeout(timeId);
        }
    }, [state.popUpAlertState.showPopUpAlert, dispatch]);

    return (
        <Fade in={state.popUpAlertState.showPopUpAlert} style={{ padding: '15px', position: 'relative', zIndex: 1500 }}>
            {state.popUpAlertState.showPopUpAlert &&
                <Alert
                    status={state.popUpAlertState.popUpAlertStatus}
                    boxShadow='lg'
                    sx={{
                        borderRadius: '10px'
                    }}
                >
                    <AlertIcon boxSize='30px' />
                    <Box
                        sx={{
                            width: '100%',
                            textAlign: 'center',
                            marginLeft: '-12px'
                        }}
                    >
                        {state.popUpAlertState.popUpAlertTitle !== '' && <AlertTitle fontSize='sm'>{state.popUpAlertState.popUpAlertTitle}</AlertTitle>}
                        {state.popUpAlertState.popUpAlertDescription !== '' && <AlertDescription fontSize='sm' style={{ whiteSpace: 'pre-line' }}>{state.popUpAlertState.popUpAlertDescription}</AlertDescription>}
                    </Box>
                </Alert>
            }
        </Fade>
    );
};

export default PopUpAlert;