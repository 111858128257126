import React, { useContext, useEffect } from "react";
import {
    Box,
    CircularProgress,
    Container,
    Modal,
    ModalContent,
    ModalOverlay,
    Center,
    Heading,
    Card,
    CardBody,
    Text,
    TableContainer,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td
} from "@chakra-ui/react";
import { store } from "../helper/Store";
import CoursewiseCount from "../components/CoursewiseCount.js";
import Count from "../components/Count.js";
import LeadsUsersCoursewiseCount from "../components/LeadsUsersCoursewiseCount.js";
import PotentialsUsersCoursewiseCount from "../components/PotentialsUsersCoursewiseCount.js";
import moment from "moment";

const Dashboard = ({ matrix1Ref, matrix2Ref, matrix3Ref, matrix4Ref }) => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const dateObject = new Date();
    const monthValue = dateObject.getMonth();
    const month = monthValue > 7 ? 'January' : monthValue > 2 ? 'September' : 'April';
    const year = monthValue > 7 ? dateObject.getFullYear() + 1 : dateObject.getFullYear();
    const intakeQuery = `AND intake = '${month}' AND intake_year = '${year}'`;

    const reportsMatrix1 = [
        {
            title: 'Total Available Leads',
            module: 'Leads',
            query: `leadstatus != 'Converted' AND course_type = 'Main Course' `,
            subject: 'leads_count_except_converted',
            color: '#285729'
        },
        {
            title: ' -- Open ',
            module: 'Leads',
            query: `leadstatus = 'Open' AND course_type = 'Main Course'`,
            subject: 'leads_count_open',
            color: '#3ea2d4'
        },
        {
            title: ' -- Following Up ',
            module: 'Leads',
            query: `leadstatus = 'Following' AND course_type = 'Main Course'`,
            subject: 'leads_count_following',
            color: '#54c7a4'
        },
        {
            title: ' -- Not Qualified ',
            module: 'Leads',
            query: `leadstatus IN ('Junk', 'Not Interested') AND course_type = 'Main Course'`
        },
        {
            title: 'Total Potential Students',
            module: 'Potentials',
            query: `potential_stage != 'Enrolled' AND student_eligibility !='SEC' ${intakeQuery}`,
            subject: 'potentials_count_total',
            color: '#08f527'
        },
        {
            title: ' -- Following up ',
            module: 'Potentials',
            query: `potential_stage = 'Followup' AND student_eligibility !='SEC'  ${intakeQuery}`,
            subject: 'potentials_count_total',
            color: '#08f527'
        },
        {
            title: ' -- Registration form sent (RFS)',
            module: 'Potentials',
            query: `potential_stage = 'Registration'  AND student_eligibility !='SEC'  ${intakeQuery}`,
            subject: 'potentials_count_registration',
            color: '#e39acb'
        },
        {
            title: ' -- Registration Payment Pending (RPP) ',
            module: 'Potentials',
            query: `potential_stage = 'Registration Payment Pending'  AND student_eligibility !='SEC'  ${intakeQuery}`,
            subject: 'potentials_count_registration_payment_pending',
        },
        {
            title: ' -- Registration Payment Confirmed (RPC)',
            module: 'Potentials',
            query: `potential_stage = 'Registration Paid'  AND student_eligibility !='SEC'  ${intakeQuery}`,
            subject: 'potentials_count_registration_paid',
            color: '#e8157e'
        },
        {
            title: 'Total Candidates',
            module: 'Candidates',
            query: `candidate_status != 'Enrolled'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_total'
        },
        {
            title: ' -- Aptitude Test Pending',
            module: 'Candidates',
            query: `candidate_status = 'Followup'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_aptitude_pending'
        },
        {
            title: ' -- Aptitude Test Scheduled',
            module: 'Candidates',
            query: `candidate_status = 'Aptitude Scheduled'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_aptitude_scheduled'
        },
        {
            title: ' -- Aptitude Test Passed',
            module: 'Candidates',
            query: `candidate_status = 'Aptitude Passed'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_aptitude_passed'
        },
        {
            title: ' -- Aptitude Test Failed',
            module: 'Candidates',
            query: `candidate_status = 'Aptitude Failed'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_aptitude_failed'
        },
        {
            title: ' -- Enrollment Payment Form Sent',
            module: 'Candidates',
            query: `candidate_status = 'Pending payment CERT III'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_pending_payment_cert_iii',
            color: '#967333'
        },
        {
            title: ' -- Enrollment Payment Confirmation Pending',
            module: 'Candidates',
            query: `candidate_status = 'CERT III Paid' AND cert_iii_payment = 'Pending'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_pending_payment_confirmation_cert_iii',
            color: '#5ce05a'
        },
        {
            title: ' -- Enrollment Payment Paid ',
            module: 'Candidates',
            query: `candidate_status = 'CERT III Paid' AND cert_iii_payment = 'Paid'   AND student_eligibility !='SEC'   ${intakeQuery}`,
            subject: 'candidates_count_paid_payment_cert_iii',
            color: '#5ce05a'
        },
        {
            title: 'Total Students',
            module: 'Students',
            query: `${intakeQuery.replace('AND ', '')}  AND student_eligibility !='SEC' `,
            subject: 'students_count_total'
        }
    ];

    const reportsMatrix2 = [
        {
            title: 'Total Available Leads',
            module: 'leads',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'all'
        },
        {
            title: ' -- Open',
            module: 'leads',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Open'
        },
        {
            title: ' -- Following Up ',
            module: 'leads',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Following'
        },
        {
            title: ' -- Not Qualified ',
            module: 'leads',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Not Qualified'
        },
        {
            title: 'Total Potential Students',
            module: 'Potentials',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'All'
        },
        {
            title: ' -- Following Up ',
            module: 'Potentials',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Followup'
        },
        {
            title: ' -- Registration form sent (RFS)',
            module: 'Potentials',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Registration'
        },
        {
            title: ' -- Registration Payment Pending (RPP)',
            module: 'Potentials',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Registration Payment Pending'
        },
        {
            title: ' -- Registration Payment Confirmed (RPC)',
            module: 'Potentials',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Registration Paid'
        },
        {
            title: 'Total Candidates',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'All'
        },
        {
            title: ' -- Aptitude Test Pending ',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Aptitude Pending'
        },
        {
            title: ' -- Aptitude Test Scheduled ',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Aptitude Scheduled'
        },
        {
            title: ' -- Aptitude Test Passed',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Aptitude Passed'
        },
        {
            title: ' -- Aptitude Test Failed ',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Aptitude Failed'
        },
        {
            title: ' -- Enrollment Payment Form Sent',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Enrollement Payment form sent'
        },
        {
            title: ' -- Enrollment Payment Confirmation Pending ',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Enrollement Payment confirmation Pending'
        },
        {
            title: ' -- Enrollment Payment Paid',
            module: 'Candidates',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'Enrollement Payment paid'
        },
        {
            title: 'Total Students',
            module: 'Students',
            course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
            course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
            course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
            course_em: 'Event Management [Diploma] - (Part time - 2 years)',
            course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)',
            query: 'all'
        }
    ];

    const reportsMatrix = {
        course_cc: 'Commercial Cookery [Certificate IV] - (Full time - 1.5 years)',
        course_hm: 'Hospitality Management [Advanced Diploma] - (Full time - 2 years)',
        course_pc: 'Patisserie [Certificate IV] - (Full time - 1.5 years)',
        course_em: 'Event Management [Diploma] - (Part time - 2 years)',
        course_tt: 'Travel and Tourism Management [Advanced Diploma] - (Full time - 1.5 years)'
    };

    useEffect(() => {
        dispatch({ type: 'loaded' });
    }, [dispatch]);

    return (
        <Container
            borderRadius={5}
            maxW='container.auto'
            bgColor='blue.100'
            color='#262626'
            position='fixed'
            top='148px'
            bottom={0}
            maxHeight='calc(100vh - 148px)'
            overflowY='scroll'
        >
            <Box fontSize={{ base: '16px', xs: '12px', sm: '14px', md: '16px' }} >
                <Box borderRadius={5} bg='gray.100' color='blue.900' style={{ padding: 5 }}>
                    <Center paddingY={5}>
                        <Heading ref={matrix1Ref} as='h1' size='md' textAlign='center' >Intake Snapshot, {month} {year}</Heading>
                    </Center>
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        align='center'
                        borderRadius={10}
                        marginX={{ base: '5px', sm: '0px', md: '5px', lg: '5px' }}
                    >
                        <CardBody>
                            <TableContainer whiteSpace={{ base: "nowrap", xs: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}>
                                <Table size={{ base: 'md', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}>
                                    <Thead>
                                        <Tr>
                                            <Th sx={{ borderColor: 'gray.300' }}>Stage</Th>
                                            <Th isNumeric sx={{ borderColor: 'gray.300' }}>Count</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {reportsMatrix1.map((report, index) => (
                                            <Tr key={`tr-${index}`}>
                                                <Td key={`td-${index}-1`} sx={{ paddingTop: '10px !important', paddingBottom: '10px !important', borderColor: 'blue.600' }} >
                                                    <Text>{report.title}</Text>
                                                </Td>
                                                <Td key={`td-${index}-2`} isNumeric sx={{ paddingTop: '10px !important', paddingBottom: '10px !important', borderColor: 'blue.600' }} >
                                                    <Count module={report.module} {...(Object.hasOwn(report, "query") ? { query: report.query } : {})} />
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </CardBody>
                    </Card>
                </Box>
                <Box borderRadius={5} bg='gray.100' color='blue.900' style={{ padding: 5 }}>
                    <Center paddingY={5}>
                        <Heading ref={matrix2Ref} as='h1' size='md' textAlign='center' >Intake Snapshot Course-wise, {month} {year}</Heading>
                    </Center>
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        align='center'
                        borderRadius={10}
                        marginX={{ base: '5px', sm: '0px', md: '5px', lg: '5px' }}
                    >
                        <CardBody>
                            <CoursewiseCount matrix={reportsMatrix2} module='Leads' query={{ intake: month, intakeyear: year }} />
                        </CardBody>
                    </Card>
                </Box>
                <Box borderRadius={5} bg='gray.100' color='blue.900' style={{ padding: 5 }}>
                    <Center paddingY={5}>
                        <Heading ref={matrix3Ref} as='h1' size='md' textAlign='center' >Leads Snapshot Course-wise, {month} {year}</Heading>
                    </Center>
                    <Card
                        direction={{ base: 'column', sm: 'row' }}
                        overflow='hidden'
                        variant='outline'
                        align='center'
                        borderRadius={10}
                        marginX={{ base: '5px', sm: '0px', md: '5px', lg: '5px' }}
                    >
                        <CardBody>
                            <LeadsUsersCoursewiseCount matrix={reportsMatrix} module='Leads' />
                        </CardBody>
                    </Card>
                </Box>
                <Box borderRadius={5} bg='gray.100' color='blue.900' style={{ padding: 5 }}>
                    <Center paddingY={5}>
                        <Heading ref={matrix4Ref} as='h1' size='md' textAlign='center' >Performance of the student counselors</Heading>
                    </Center>
                    <PotentialsUsersCoursewiseCount matrix={reportsMatrix} module='Potentials' query={{ from: moment().subtract(7, 'days').format('YYYY-MM-DD'), to: moment().format('YYYY-MM-DD') }} />
                </Box>
            </Box>
            <Modal
                isCentered
                isOpen={state.isLoading}
                onClose={() => { }}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px) hue-rotate(90deg)'
                    sx={{
                        marginTop: '104px'
                    }}
                />
                <ModalContent
                    sx={{
                        alignItems: 'center',
                        backgroundColor: 'inherit',
                        boxShadow: 'none'
                    }}
                >
                    <CircularProgress isIndeterminate color='orange.500' size='70px' />
                </ModalContent>
            </Modal>
        </Container >

    );
};

export default Dashboard;