// eslint-disable-next-line
import React, { useState, useEffect, useContext } from "react";
import { store } from "../helper/Store";
import CONF from '../config.json';
import { useApi } from "./VtigerApiProvider";


const prepareRequest = (endpoint, body, header = '') => {
    let params = {
        method: 'POST',
        body: JSON.stringify(body),
    }
    if (header !== '') {
        params['headers'] = new Headers({ 'Content-Type': header });
    }
    return new Request(endpoint, params);
};

export const useFetchCustomQueryCoursewise = (module, query) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'coursewise', 'filters': query }, 'application/json');
            const response = await (await fetch(request)).json();

            setData(response);
        };

        fetchCustomQuery();
    }, [module, query, state.token]);

    return { data: data };
};

export const useFetchCustomQueryLeadsUsersCoursewise = (module) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'currentleads', 'filters': [] }, 'application/json');
            const response = await (await fetch(request)).json();

            setData(response);
        };

        fetchCustomQuery();
    }, [module, state.token]);

    return { data: data };
};

export const useFetchCustomQueryPotentialsUsersCoursewiseCount = (module, query) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const [data, setData] = useState();

    useEffect(() => {
        const fetchCustomQuery = async () => {
            const request = prepareRequest(CONF.CRM_CUSTOM_API, { '_operation': 'fetchCustomQuery', '_session': state.token, 'module': module, 'type': 'conversionpotential', 'filters': query }, 'application/json');
            const response = await (await fetch(request)).json();

            setData(response);
        };

        fetchCustomQuery();
    }, [module, query, state.token]);

    return { data: data };
};

export const useFetchQuery = (module, select, query) => {
    const api = useApi();
    const [data, setData] = useState();

    useEffect(() => {
        const fetchQuery = async () => {
            api.fetchQuery(module, select, query).then((data) => {
                setData(data);
            });
        };

        fetchQuery();
    }, [module, select, query, api]);

    return { data: data };
};