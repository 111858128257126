import React from "react";
import { useFetchCustomQueryLeadsUsersCoursewise } from "../api/fetchData";
import { Skeleton, Text, TableContainer, Table, Thead, Th, Tbody, Tr, Td, useMediaQuery } from "@chakra-ui/react";

const LeadsUsersCoursewiseCount = ({ matrix, module }) => {
    const [isLagerThan420] = useMediaQuery('(min-width: 420px)');
    const [isLagerThan440] = useMediaQuery('(min-width: 440px)');
    const [isLagerThan600] = useMediaQuery('(min-width: 600px)');
    const [isLagerThan800] = useMediaQuery('(min-width: 800px)');

    const { data } = useFetchCustomQueryLeadsUsersCoursewise(module);

    // if ( !data ) {
    //     return <Skeleton fadeDuration={5} height={{ base: '777px' }} />
    // }

    return (
        <Skeleton fadeDuration={5} height={data ? `${106 + ((isLagerThan440 ? 55 : 61) * Object.keys(data['result']['record']['leads']).length)}px` : '300px'} isLoaded={data}>
            {data &&
                <TableContainer whiteSpace={{ base: "nowrap", xs: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}>
                    <Table size={{ base: 'md', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}>
                        <Thead>
                            <Tr>
                                <Th
                                    sx={{ 
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                    rowSpan={2}
                                >
                                    Users
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                    colSpan={2}
                                >
                                    CC
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                    colSpan={2}
                                >
                                    HM
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                    colSpan={2}
                                >
                                    PC
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                    colSpan={2}
                                >
                                    EM
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                    colSpan={2}
                                >
                                    TT
                                </Th>
                            </Tr>
                            <Tr>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    O
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    F
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    O
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    F
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    O
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    F
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    O
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    F
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    O
                                </Th>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                    }}
                                    textAlign='center'
                                >
                                    F
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.entries(data['result']['record']['leads']).map(([user, courses]) => (
                                <Tr key={`tr-${user}`}>
                                    <Td
                                        key={`user-${user}`}
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}>{user}</Text>
                                    </Td>
                                    <Td
                                        key={`cc-o-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_cc) ? 0 : Object.hasOwn(courses[matrix.course_cc], 'Open') ? courses[matrix.course_cc]['Open'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`cc-f-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_cc) ? 0 : Object.hasOwn(courses[matrix.course_cc], 'Following') ? courses[matrix.course_cc]['Following'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`hm-o-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_hm) ? 0 : Object.hasOwn(courses[matrix.course_hm], 'Open') ? courses[matrix.course_hm]['Open'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`hm-f-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_hm) ? 0 : Object.hasOwn(courses[matrix.course_hm], 'Following') ? courses[matrix.course_hm]['Following'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`pc-o-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_pc) ? 0 : Object.hasOwn(courses[matrix.course_pc], 'Open') ? courses[matrix.course_pc]['Open'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`pc-f-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_pc) ? 0 : Object.hasOwn(courses[matrix.course_pc], 'Following') ? courses[matrix.course_pc]['Following'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`em-o-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_em) ? 0 : Object.hasOwn(courses[matrix.course_em], 'Open') ? courses[matrix.course_em]['Open'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`em-f-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_em) ? 0 : Object.hasOwn(courses[matrix.course_em], 'Following') ? courses[matrix.course_em]['Following'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`tt-o-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_tt) ? 0 : Object.hasOwn(courses[matrix.course_tt], 'Open') ? courses[matrix.course_tt]['Open'] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`tt-f-${user}`}
                                        textAlign='center'
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '5px !important' : 6)
                                        }}
                                    >
                                        <Text
                                            fontWeight='bold'
                                            fontSize={!isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '12px' : isLagerThan420 && !isLagerThan600 && !isLagerThan800 ? '14px' : '16px'}
                                        >
                                            {!Object.hasOwn(courses, matrix.course_tt) ? 0 : Object.hasOwn(courses[matrix.course_tt], 'Following') ? courses[matrix.course_tt]['Following'] : 0}
                                        </Text>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
        </Skeleton>
    );
};

export default LeadsUsersCoursewiseCount;