import React, { useContext, useRef, useState } from 'react';
import {
    Container,
    Button,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputRightElement,
    IconButton,
    InputGroup, FormErrorMessage
} from '@chakra-ui/react';
import { store } from "../helper/Store";
import { useAuth } from "../api/AuthProvider";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";


const Login = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const auth = useAuth();
    const usernameRef = useRef(null);
    const passwordRef = useRef(null);
    const [input, setInput] = useState({
        username: "admin",
        password: ""
    });
    const [showPassword, setShowPassword] = useState(false);
    const [isUsernameEmpty, setIsUsernameEmpty] = useState(false);
    const [isPasswordEmpty, setIsPasswordEmpty] = useState(false);

    const handleInput = (event) => {
        const { name, value } = event.target;
        switch (name) {
            case 'username':
                if (value !== "") {
                    setIsUsernameEmpty(false);
                }
                break;
            case 'password':
                if (value !== "") {
                    setIsPasswordEmpty(false);
                }
                break;
            default:
                break;
        }
        setInput((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleShowHidePasswordClick = () => setShowPassword(!showPassword);

    const handleLogin = (event) => {
        event.preventDefault();

        if (input.username === "") {
            setIsUsernameEmpty(true);
            usernameRef.current.focus();
            return false;
        }

        if (input.password === "") {
            setIsPasswordEmpty(true);
            passwordRef.current.focus();
            return false;
        }

        dispatch({ type: 'loading' });

        auth.login(input.username, input.password).then(() => {

        }, (reason) => {
            switch ( reason.error.code ) {
                case 1210:
                    dispatch({ type: 'show_popup_alert', popUpAlertState: { showPopUpAlert: true, popUpAlertStatus: Object.keys(reason)[1], popUpAlertTitle: reason.error.message, popUpAlertDescription: "Please check your credentials" } });
                    passwordRef.current.focus();
                    //notify('Invalid User or password', { type: Object.keys(reason)[1] });
                    break;
                case 1220:
                    //notify(`${reason.error.message}`, { type: Object.keys(reason)[0] });
                    //logout();
                    break;
                default:
                    break;
            }
            dispatch({ type: 'loaded' });
        });
    };

    return (
        <Box
            borderRadius={16}
            bgGradient="linear(to-l, gray.100, gray.200)"
            position='relative'
            top='91px'
            sx={{
                height: 'calc(100vh - 104px)',
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Container
                borderRadius={14}
                maxW='container.md'
                bg='#73d6c253'
                color='#262626'
            >
                <Box color='gray.50'>
                    <FormControl isInvalid={isUsernameEmpty}>
                        <FormLabel mb={2} mt={5} pl={1} color='blue.900' >Username</FormLabel>
                        <Input
                            bgGradient="linear(to-l, blue.100, blue.50)"
                            color='blue.800'
                            name='username'
                            type='text'
                            value={input.username}
                            ref={usernameRef} {...(isUsernameEmpty ? { focusBorderColor: 'crimson' } : {})}
                            onChange={handleInput} />
                        {isUsernameEmpty && <FormErrorMessage>Please enter username</FormErrorMessage>}
                    </FormControl>
                    <FormControl isInvalid={isPasswordEmpty}>
                        <FormLabel mb={2} mt={3} pl={1} color='blue.900' >Password</FormLabel>
                        <InputGroup>
                            <Input
                                bgGradient="linear(to-l, blue.100, blue.50)"
                                color='blue.800'
                                name='password'
                                type={showPassword ? "text" : "password"}
                                ref={passwordRef}
                                {...(isPasswordEmpty ? { focusBorderColor: 'crimson' } : {})}
                                onChange={handleInput}
                                onFocus={(event) => event.currentTarget.setSelectionRange(event.currentTarget.value.length, event.currentTarget.value.length)}
                            />
                            <InputRightElement>
                                <IconButton aria-label='Show/Hide password' isRound={true} variant='ghost' onClick={handleShowHidePasswordClick} icon={showPassword ? <ViewIcon color='blue.900' /> : <ViewOffIcon color='blue.900' />} />
                            </InputRightElement>
                        </InputGroup>
                        {isPasswordEmpty && <FormErrorMessage>Please enter password</FormErrorMessage>}
                    </FormControl>

                    <FormControl style={{ marginTop: 5 }}>
                        <Button mt={4} mb={2} bgImage='linear-gradient(to bottom, #61d1bb 0px, #61d1bb 100%)' color='white' size='md' _hover={{ bgImage: 'linear-gradient(to bottom, #8adbcb 0px, #8adbcb 100%)' }} onClick={handleLogin} isLoading={state.isLoading} loadingText='Signing in...' spinnerPlacement='end' >
                            Sign in
                        </Button>
                    </FormControl>
                </Box>
            </Container >
        </Box >
    );
};
// eslint-disable-next-line
const styles = {
    label: {
        color: '#e0e0e0'
    },
    container: {
        padding: 10,
        color: '#1190c0'
    }
};
export default Login
