import React from "react";
import { Text, Skeleton } from "@chakra-ui/react";
import { useFetchQuery } from "../api/fetchData";

const Count = ({ module, select='COUNT(*)', query=null }) => {
    const { data } = useFetchQuery(module, select, query);

    // if ( !data ) {
    //     return <Skeleton fadeDuration={5} height='20px' isLoaded={data} />
    // }

    return (
        <Skeleton fadeDuration={5} height='20px' isLoaded={data} >
            {data && <Text fontWeight='bold'>{data[0].count}</Text>}
        </Skeleton>
    );
};

export default Count;