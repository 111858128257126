import React, { useContext, createContext } from "react";
import { store } from "../helper/Store";
import CONF from '../config.json';

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
    const globalState = useContext(store);
    const { state, } = globalState;

    const prepareRequest = (body, header = '') => {
        let params = {
            method: 'POST',
            body: JSON.stringify(body),
            // headers: new Headers({ 'Content-Type': header }),
        }
        if (header !== '') {
            params['headers'] = new Headers({ 'Content-Type': header });
        }
        return new Request(CONF.CRM_ENDPOINT, params);
    };

    const describeEntity = (module) => {
        return new Promise((resolve, reject) => {
            const request = prepareRequest({ '_operation': 'describe', '_session': state.token, 'module': module }, 'application/json');
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(data => {
                if (data.success && data.result.hasOwnProperty('describe')) {
                    return resolve(data.result.describe)
                }
                return reject(data);
            });
        });
    };
    const saveEntityWithFile = (data, module, imgSrc = null, record = '', fileFieldName = 'filename') => {
        return new Promise((resolve, reject) => {

            const formData = new FormData();
            formData.append('_operation', 'saveRecord');
            // let type = 'image/jpeg';
            if (fileFieldName === 'signature[]') {
                // type = 'image/png'
                formData.append('action', 'MassEditSave')
            }

            formData.append('_session', state.token);
            formData.append('module', module);

            const blob = dataURItoBlob(imgSrc);

            formData.append('values', JSON.stringify(data));
            formData.append(fileFieldName, blob);

            if (record !== '') {
                formData.append('record', record);
            }
            fetch(CONF.CRM_ENDPOINT, {
                method: 'POST',
                body: formData,
                // headers: new Headers({ 'Content-Type': undefined })
            }).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(resp => {
                if (resp.success && resp.result && resp.result.record) {
                    return resolve(resp.result.record);
                } else {
                    return reject(JSON.stringify(resp.error));
                }
            }).catch((error) => {
                return reject('SaveEntityWithFileError : ' + error.message)
            });
        });
    }
    function dataURItoBlob(dataURI, type = 'image/jpeg') {
        const parts = dataURI.split(',');
        const dataString = atob(parts[1]);

        const dataArray = new Uint8Array(dataString.length);
        for (let i = 0; i < dataString.length; i++) {
            dataArray[i] = dataString.charCodeAt(i);
        }

        return new Blob([dataArray], { type: type });
    }
    const saveEntity = (data, module, record = '', header = 'application/json') => {
        return new Promise((resolve, reject) => {
            let params = {
                '_operation': 'saveRecord',
                'values': data,
                '_session': state.token,
                module: module
            }
            if (record !== '') {
                params['record'] = record;
            }
            const request = prepareRequest(params, header);
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(resp => {
                if (resp.success && resp.result && resp.result.record) {
                    return resolve(resp.result.record);
                } else {
                    return reject(resp);
                }
            }).catch(() => {
                throw new Error('API error on Fetch Entity')
            });
        });
    }
    const fetchQuery = (module, selectColumns = '*', queryString = null) => {
        return new Promise((resolve, reject) => {
            const query = `SELECT ${selectColumns} FROM ${module} ${queryString !== null ? `WHERE ${queryString}` : ''};`;
            const request = prepareRequest({ '_operation': 'query', '_session': state.token, 'query': query }, 'application/json');
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(resp => {
                if (resp.success && resp.result && resp.result.records) {
                    return resolve(resp.result.records);
                } else {
                    return reject(resp);
                }
            }).catch(() => {
                throw new Error('API error')
            });
        });
    };
    const fetchEntity = (id) => {
        return new Promise((resolve, reject) => {
            const request = prepareRequest({ '_operation': 'fetchRecord', '_session': state.token, 'record': id }, 'application/json');
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(resp => {
                if (resp.success && resp.result && resp.result.record) {
                    return resolve(resp.result.record);
                } else {
                    return reject(resp);
                }
            }).catch(() => {
                throw new Error('API error on Fetch Entity')
            });
        });
    }

    const fetchEntityWithFile = (id) => {
        return new Promise((resolve, reject) => {
            const request = prepareRequest({ '_operation': 'fetchRecordWithFile', '_session': state.token, 'file_id': id }, 'application/json');
            fetch(request).then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response);
                }
                return response.json();
            }).then(resp => {
                if (resp.success && resp.result && resp.result.record) {
                    return resolve(resp.result.record);
                } else {
                    return reject(resp);
                }
            }).catch(() => {
                throw new Error('API error on Fetch Entity')
            });
        });
    }


    return <ApiContext.Provider value={{ prepareRequest, describeEntity, fetchQuery, fetchEntity, saveEntity, saveEntityWithFile, fetchEntityWithFile }}>{children}</ApiContext.Provider>;
};

export default ApiProvider;

export const useApi = () => {
    return useContext(ApiContext);
};



