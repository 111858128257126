import React from "react";
import { useFetchCustomQueryCoursewise } from "../api/fetchData";
import { Skeleton, Text, TableContainer, Table, Thead, Th, Tbody, Tr, Td, useMediaQuery } from "@chakra-ui/react";

const CoursewiseCount = ({ matrix, module, query }) => {
    const [isLagerThan420] = useMediaQuery('(min-width: 420px)');
    const [isLagerThan600] = useMediaQuery('(min-width: 600px)');
    const [isLagerThan800] = useMediaQuery('(min-width: 800px)');

    const { data } = useFetchCustomQueryCoursewise(module, query);

    // if ( !data ) {
    //     return <Skeleton fadeDuration={5} height={{ base: '777px' }} />
    // }

    return (
        <Skeleton fadeDuration={5} height={isLagerThan420 && isLagerThan800 ? '868px' : !isLagerThan420 && !isLagerThan800 ? '1539px' : isLagerThan420 && !isLagerThan600 ? '1399px' : '779px'} isLoaded={data}>
            {data &&
                <TableContainer whiteSpace={{ base: "nowrap", xs: "wrap", sm: "wrap", md: "nowrap", lg: "nowrap" }}>
                    <Table size={{ base: 'md', xs: 'xs', sm: 'sm', md: 'md', lg: 'lg' }}>
                        <Thead>
                            <Tr>
                                <Th
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '20px !important' : 6)
                                    }}
                                >
                                    Stage
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    Total
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    CC
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    HM
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    PC
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    EM
                                </Th>
                                <Th
                                    isNumeric
                                    sx={{
                                        borderColor: 'gray.300',
                                        paddingRight: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '20px !important' : 6),
                                        paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                    }}
                                >
                                    TT
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {matrix.map((report, index) => (
                                <Tr key={`tr-${index}`}>
                                    <Td
                                        key={`title-${index}`}
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '20px !important' : 6)
                                        }}
                                    >
                                        <Text>{report.title}</Text>

                                    </Td>
                                    <Td
                                        key={`cc-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text fontWeight='bold'>
                                            {
                                                (!Object.hasOwn(data['result']['record'][report.module], report.course_pc) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_pc], report.query) ? data['result']['record'][report.module][report.course_pc][report.query] : 0) +
                                                (!Object.hasOwn(data['result']['record'][report.module], report.course_hm) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_hm], report.query) ? data['result']['record'][report.module][report.course_hm][report.query] : 0) +
                                                (!Object.hasOwn(data['result']['record'][report.module], report.course_cc) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_cc], report.query) ? data['result']['record'][report.module][report.course_cc][report.query] : 0) +
                                                (!Object.hasOwn(data['result']['record'][report.module], report.course_tt) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_tt], report.query) ? data['result']['record'][report.module][report.course_tt][report.query] : 0) +
                                                (!Object.hasOwn(data['result']['record'][report.module], report.course_em) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_em], report.query) ? data['result']['record'][report.module][report.course_em][report.query] : 0)
                                            }
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`cc-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text >
                                            {!Object.hasOwn(data['result']['record'][report.module], report.course_cc) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_cc], report.query) ? data['result']['record'][report.module][report.course_cc][report.query] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`hm-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text >
                                            {!Object.hasOwn(data['result']['record'][report.module], report.course_hm) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_hm], report.query) ? data['result']['record'][report.module][report.course_hm][report.query] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`pc-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text >
                                            {!Object.hasOwn(data['result']['record'][report.module], report.course_pc) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_pc], report.query) ? data['result']['record'][report.module][report.course_pc][report.query] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`em-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text >
                                            {!Object.hasOwn(data['result']['record'][report.module], report.course_em) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_em], report.query) ? data['result']['record'][report.module][report.course_em][report.query] : 0}
                                        </Text>
                                    </Td>
                                    <Td
                                        key={`tt-${index}`}
                                        isNumeric
                                        sx={{
                                            paddingTop: '10px !important',
                                            paddingBottom: '10px !important',
                                            borderColor: 'blue.600',
                                            paddingRight: (isLagerThan420 && !isLagerThan800 ? '20px !important' : !isLagerThan420 ? '20px !important' : 6),
                                            paddingLeft: (isLagerThan420 && !isLagerThan800 ? '10px !important' : !isLagerThan420 ? '10px !important' : 6)
                                        }}
                                    >
                                        <Text>
                                            {!Object.hasOwn(data['result']['record'][report.module], report.course_tt) ? 0 : Object.hasOwn(data['result']['record'][report.module][report.course_tt], report.query) ? data['result']['record'][report.module][report.course_tt][report.query] : 0}
                                        </Text>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            }
        </Skeleton>
    );
};

export default CoursewiseCount;