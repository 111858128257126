import React, { useContext } from "react";
import { Avatar, Box, Button, ButtonGroup, Divider, Flex, IconButton, Spacer, Tabs, TabList, Tab, TabIndicator, Center, Tooltip } from "@chakra-ui/react";
import logo from "../assets/cahm-crm-logo.png";
import { Icon } from "@chakra-ui/icons";
import { MdLogout, MdFormatListNumberedRtl, MdOutlineAnalytics, MdOutlineLeaderboard, MdOutlineRecordVoiceOver } from "react-icons/md";
import { store } from "../helper/Store";
import { useAuth } from "../api/AuthProvider";

const AppHeader = ({ matrix1Ref, matrix2Ref, matrix3Ref, matrix4Ref }) => {
    const globalState = useContext(store);
    const { state, } = globalState;
    const auth = useAuth();

    const handleTabCahnge = (index) => {
        switch (index) {
            case 0:
                matrix1Ref.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 1:
                matrix2Ref.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 2:
                matrix3Ref.current.scrollIntoView({ behavior: "smooth" });
                break;
            case 3:
                matrix4Ref.current.scrollIntoView({ behavior: "smooth" });
                break;
            default:
                break;
        }
    };

    return (
        <Box
            borderBottom={1}
            position='fixed'
            width='100%'
            zIndex={10000}
            bgImage='linear-gradient(180deg, #f5f2eb, white, #f5f2eb)'
        >
            <Flex>
                <Box style={{ ...styles.container, paddingTop: 25, paddingBottom: 25 }}>
                    <img src={logo} width={150} height={27} alt="auzy-logo" />
                </Box>
                <Spacer />
                <Box
                    sx={{
                        alignSelf: 'center'
                    }}
                >
                    {state.isLoggedIn &&
                        <ButtonGroup spacing='0'>
                            <Button variant='ghost' size='lg' ><Avatar name={auth.userData.username} />&nbsp;&nbsp;&nbsp;{auth.userData.username}</Button>
                            <IconButton aria-label='Logout' variant='ghost' size='lg' isRound={true} icon={<Icon as={MdLogout} boxSize={6} />} onClick={auth.logout} />
                        </ButtonGroup>
                    }
                </Box>
            </Flex>
            <Divider />
            {state.isLoggedIn &&
                <Center>
                    <Tabs defaultIndex={0} onChange={(index) => handleTabCahnge(index)} >
                        <TabList>
                            <Tooltip label="Current Intake Snapshot" aria-label="A tooltip">
                                <Tab sx={{ color: '#73d6c2', _selected: { bg: 'blue.100' } }}>
                                    <Icon as={MdFormatListNumberedRtl} boxSize={10} />
                                </Tab>
                            </Tooltip>
                            <Tooltip label="Current Intake Coursewise Snapshot" aria-label="A tooltip">
                                <Tab sx={{ color: '#73d6c2', _selected: { bg: 'green.100' } }}>
                                    <Icon as={MdOutlineAnalytics} boxSize={10} />
                                </Tab>
                            </Tooltip>
                            <Tooltip label="Coursewise Leads snapshot" aria-label="A tooltip">
                                <Tab sx={{ color: '#73d6c2', _selected: { bg: 'green.100' } }}>
                                    <Icon as={MdOutlineLeaderboard} boxSize={10} />
                                </Tab>
                            </Tooltip>
                            <Tooltip label="Student counselors Performance" aria-label="A tooltip">
                                <Tab sx={{ color: '#73d6c2', _selected: { bg: 'green.100' } }}>
                                    <Icon as={MdOutlineRecordVoiceOver} boxSize={10} />
                                </Tab>
                            </Tooltip>
                        </TabList>
                        <TabIndicator mt='-1.5px' height='2px' bg='blue.500' borderRadius='1px' />
                    </Tabs>
                </Center>
            }
        </Box>
    );
};

const styles = {

    container: {
        fontFamily: 'sans-serif',
        padding: 10,
        alignContent: 'center',

    }
};

export default AppHeader;